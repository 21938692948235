@font-face {
  font-family: "Satoshi-Medium", sans-serif;
  src: url("../src/fonts/Satoshi-Medium.woff2") format("woff2")
    url("../src/fonts/Satoshi-Medium.woff") format("woff")
    url("../src/fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi-Bold";
  src: url("../src/fonts/Satoshi-Bold.woff2") format("woff2"),
    url("../src/fonts/Satoshi-Bold.woff") format("woff"),
    url("../src/fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: 0;
}

html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  display: none;
}

i dl ol ul {
  margin-bottom: 0.5rem;
}
:root {
  --color-bg: #ffffff;
  --color-white: #fff;
  --color-secondary: #ff7868;
  --color-orange: #fd6f20;
  --color-black: #0d0d0d;
  --form-bg: #242424;
  --black-800: #464646;
  --background: #ffe2d2;
  --black-900: #1e1e1e;
  --inputBorderColor: #e0dfe3;
  --banner-color: #d9d9d9;
  --Grey: #9d9d9d;
  --heavy: 700;
  --normal: 500;
}

html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  display: none;
}
body {
  background-color: var(--color-bg);
  line-height: 1.7;
}

a {
  text-decoration: underline;
  color: var(--color-black);
}
a:hover {
  text-decoration: underline;
  color: var(--color-orange);
}
h1 h2 h3 h4 h5 h6 {
  font-weight: 500;
}
button:focus {
  outline: none;
}

img {
  object-fit: cover;
  width: 100%;
}
.heavy {
  font-weight: var(--heavy);
}
.normal {
  font-weight: var(--normal);
}
.secondary {
  color: var(--color-secondary);
}
.primary {
  color: var(--color-orange);
}
.login-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.padding-zero {
  padding: 0;
}
.banner-img {
  width: 382px;
  height: 367px;
}

.logo {
  width: 53px;
  height: 34px;
  flex-shrink: 0;
}
.direction-column {
  display: flex;
  flex-direction: column;
}
.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
label {
  display: flex;
  align-items: center;
  gap: 3px;
  font-weight: 500;
  color: var(--black-900);
  font-family: "Satoshi", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
}
input {
  padding: 0 10px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid var(--inputBorderColor);
  background: var(--color-white);
}

.checkbox {
  display: none;
}

.margin_form {
  margin-top: 24px;
  margin-bottom: 16px;
}
table,
thead,
tbody {
  width: 100%;
  border: 1px solid black;
}

.welcome-heading {
  margin-top: 16px;
  margin-bottom: 48px;
  color: var(--black-900);
  font-family: "Satoshi", sans-serif;
  font-size: 32px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -2px;
}

.custom-checkbox {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid var(--color-orange);
  background: var(--color-white);
  cursor: pointer;
}

input[type="checkbox"]:checked + .custom-checkbox {
  background-color: var(--color-orange);
  border: 2px solid var(--color-orange);
}

.custom-checkbox::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("./Assets/Images/check.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  visibility: hidden;
}

input[type="checkbox"]:checked + .custom-checkbox::before {
  visibility: visible;
}
.forget {
  color: var(--color-secondary);
  text-decoration: none;
  font-family: "Satoshi", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.5px;
}
.forget:hover {
  text-decoration: none;
}
.username-message {
  font-size: 12px;
  color: var(--color-secondary);
  font-family: "Satoshi", sans-serif;
  font-weight: 500;
  padding-top: 8px;
}

.submit-btn {
  margin-top: 32px;
  height: 50px;
  width: 100%;
  border-radius: 5px;
  background: var(--color-orange);
}
.heading-submit {
  color: var(--color-white);
  text-align: center;
  font-family: "Satoshi", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.5px;
}
.horizontal-line {
  display: flex;
  align-items: center;
  text-align: center;
}

.horizontal-line hr {
  flex: 1;
  border: none;
  border-top: 0.5px solid var(--Grey);
  margin: 0 10px;
}

.login-heading {
  margin-top: 32px;
  margin-bottom: 32px;
  color: var(--Grey);
  text-align: center;
  font-family: "Satoshi", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.5px;
}
.google-btn {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid var(--color-orange);
  background: var(--color-white);
}
.google-icon {
  width: 18px;
  height: 18px;
}
.sign-heading {
  color: var(--black-800);
  font-family: "Satoshi", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.5px;
}
.sign-link {
  margin-top: 40px;
  text-align: center;
  color: var(--black-800);
  font-family: "Satoshi", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
}
.center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.justify-center {
  display: flex;
  justify-content: center;
}
.banner-container {
  height: 100vh;
  display: flex;
  align-items: center;
  background: var(--background);
  justify-content: center;
}

.sign-container {
  height: 100vh;
  display: flex;
  align-items: center;
  background: var(--banner-color);
  justify-content: center;
}
.marginBottom-zero {
  margin-bottom: 0;
}
.margin_twentyfour {
  margin-bottom: 24px;
}
